@font-face {
  font-family: LightCable;
  src: url("fonts/lightcable.otf") format("opentype");
}

@font-face {
  font-family: Montserrat;
  src: url("fonts/montserrat-regular.otf") format("opentype");
}

@font-face {
  font-family: MontserratLight;
  src: url("fonts/montserrat-hairline.otf") format("opentype");
}

html,
body {
  height: 100%;
  margin: 0;
}

body {
  background-color: black;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.content-background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  background: rgba(26, 39, 58, 0.7);
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  z-index: -1;
}

.content-outer {
  display: block;
  overflow: auto;
  width: 100%;
  color: white;
  margin: 0;
  /* padding: 8vw 0 6vw 0; */
  padding: 12vw 0 6vw;
}

.logo-main {
  font-family: "LightCable", sans-serif;
  font-size: 14.1vw;
  margin: 0 10%;
  padding: 0;
  line-height: 1;
}

.logo-tagline {
  text-align: justify;
  text-align-last: justify;
  font-family: "Montserrat", sans-serif;
  font-size: 4.4vw;
  letter-spacing: 0.1em;
  margin: -0.5em 10% 0 10%;
  padding: 0;
  line-height: 1;
}

hr {
  border: 0px solid white;
  margin: 4vw 10%;
  padding: 0;
  height: 0.3vw;
  background: white;
}

.navigation-button {
  height: 4.4vw;
  font-size: 4.4vw;
  font-family: sans-serif;
  display: inline-block;
  width: 5%;
  margin: 0;
  padding: 0;
  /* vertical-align: top; */
}

.navigation-button#previous {
  padding-left: 10%;
  text-align: left;
}

.navigation-button#next {
  padding-right: 10%;
  text-align: right;
}

.navigation-button#previous button,
.navigation-button#next button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: inherit;
}

.navigation-button#next button {
  text-align: right;
}

.navigation-button#previous button {
  text-align: left;
}

.arrow {
  display: inline-block;
  width: 2.6vw;
  height: 2.6vw;
  /* transform-origin: top center; */
  vertical-align: middle;
  /* margin-top: -0.5vw; */
  border-top: 0.4vw solid white;
}

.navigation-button#previous .arrow {
  border-left: 0.4vw solid white;
  transform: rotate(315deg);
  /* margin-left: -0.5vw; */
  justify-content: flex-start;
}

.navigation-button#next .arrow {
  border-right: 0.4vw solid white;
  transform: rotate(45deg);
  /* margin-right: -0.5vw; */
  justify-content: flex-end;
}

.coordinates-primary {
  font-family: "Montserrat", sans-serif;
  font-size: 4.4vw;
  margin: 0;
  line-height: 100%;
}

.coordinates-secondary {
  font-family: "Montserrat", sans-serif;
  font-size: 2.6vw;
  margin: 0;
  line-height: 200%;
}

.line-up {
  font-family: "Montserrat", sans-serif;
  font-size: 4.4vw;
}

.artist-name-block {
  display: inline-block;
  width: 70%;
  /* height: 4.4vw; */
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 4.4vw;
  margin: 0;
  padding: 0;
  line-height: 1;
}

p {
  margin: 4vw 0;
}

.artist-country {
  font-family: "MontserratLight", sans-serif;
  font-size: 2.6vw;
  /* padding-left: 5px; */
}

.content-block {
  width: 80%;
  text-align: left;
  font-family: "MontserratLight", sans-serif;
  font-size: 1.4em;
  line-height: 1.4em;
  margin: 0 10%;
  padding: 0;
}

.content-block a:hover {
  text-decoration: underline;
}

.line-up {
  line-height: 80%;
}

.stage {
  font-family: "MontserratLight", sans-serif;
  font-size: 4.4vw;
  letter-spacing: 0.4em;
  display: inline-block;
  margin: 0 4vw 2vw 0;
}

.line-up a {
  display: inline-block;
  margin: 0 4vw 2vw 0;
}

.line-up a:hover {
  text-decoration: none;
}

.line-up a:hover .artist-name {
  text-decoration: underline;
}

.content-block.center {
  text-align: center;
}

.timestamp {
  font-size: 1.2em;
}

.video-outer {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  overflow: hidden;
}

.video-outer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.footer-logo {
  margin: 0 4vw 4vw 4vw;
}

.footer-text {
  font-family: "Montserrat", sans-serif;
  color: #999999;
  font-size: 0.6em;
  line-height: 100%;
  margin: 0;
}

@media screen and (min-width: 720px) {
  .content-outer {
    padding: 8vw 0 6vw;
  }
}

@media screen and (min-width: 1680px) {
  .content-background {
    width: 50%;
  }

  .content-outer {
    width: 50%;
    padding: 4vw 0 3vw 0;
  }

  .logo-main {
    font-size: 5.35vw;
    margin: 0 20%;
  }

  .logo-tagline {
    margin: -0.5em 20% 0 20%;
    font-size: 1.65vw;
  }

  hr {
    margin: 2vw 20%;
    height: 0.15vw;
  }

  .coordinates-primary {
    font-family: "Montserrat", sans-serif;
    font-size: 1.65vw;
  }

  .coordinates-secondary {
    font-family: "Montserrat", sans-serif;
    font-size: 1vw;
  }

  .stage {
    font-size: 1.65vw;
    margin: 0 1.5vw 0.8vw 0;
  }

  .line-up {
    font-size: 1.65vw;
  }

  .line-up a {
    margin: 0 1.5vw 0.8vw 0;
  }

  .navigation-button#previous,
  .navigation-button#next {
    width: 20%;
    text-align: center;
    padding: 0;
    height: 1.65vw;
    font-size: 1.65vw;
    justify-content: center;
  }

  .navigation-button#previous button,
  .navigation-button#next button {
    text-align: center;
  }

  .arrow {
    width: 0.825vw;
    height: 0.825vw;
    border-top: 0.15vw solid white;
  }

  .navigation-button#previous .arrow {
    border-left: 0.15vw solid white;
    margin: 0;
  }

  .navigation-button#next .arrow {
    border-right: 0.15vw solid white;
    margin: 0;
  }

  .artist-name-block {
    font-size: 1.65vw;
    /* height: 1.65vw; */
    width: 60%;
  }

  .artist-country {
    font-size: 1vw;
  }

  .content-block {
    width: 60%;
    margin: 0 20%;
  }

  p {
    margin: 2vw 0;
  }

  .footer-logo {
    margin: 0 2vw 2vw 2vw;
  }
}

button {
  display: inline-block;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  background: none;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

button:active {
  transform: scale(0.99);
}

html {
  overflow: hidden;
  height: 100%;
}

body {
  height: 100%;
  overflow: auto;
}

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 1s;
}
.fade-exit {
  opacity: 0;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 1s;
}

/* .fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-out, transform 300ms ease;
}
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 250ms ease-out, transform 300ms ease;
} */

.fadeIn {
  -webkit-animation: fadein 1s linear;
  -moz-animation: fadein 1s linear;
  -ms-animation: fadein 1s linear;
  -o-animation: fadein 1s linear;
  animation: fadein 1s linear;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* fix for removing highlight after on click */
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* remove hover on touchdevices */
@media (hover: none) {
  .content-block a:hover {
    text-decoration: inherit;
  }
  .line-up a:hover .artist-name {
    text-decoration: inherit;
  }
}
.isMobile a:hover {
  text-decoration: none;
}
.isMobile .content-block a:hover {
  text-decoration: none;
}
.isMobile .line-up a:hover .artist-name {
  text-decoration: none;
}

body,
html {
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
#root {
  overflow: auto;
  height: 100%;
}

.content-block .ArtistContent p {
  display: flex;
  flex-wrap: wrap;
}

/* #ReactBackgroundSlider > figure {
  position: fixed !important;
  height: 100vh !important;
} */
