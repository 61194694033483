a.Button {
  background-color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 3.4vw;
  padding: 0.6vw 1vw;
  color: rgba(26, 39, 58, 1);
  line-height: 1;
  vertical-align: center;
}

a.Button:hover {
  opacity: 0.9;
  text-decoration: none !important;
}

a.Button .arrow {
  display: inline-block;
  width: 1.6vw;
  height: 1.6vw;
  transform-origin: top center;
  vertical-align: middle;
  margin-top: 0vw;
  border-top: 0.35vw solid rgba(26, 39, 58, 1);
  border-right: 0.35vw solid rgba(26, 39, 58, 1);
  transform: rotate(45deg);
  margin-right: 0.3vw;
}
@media screen and (min-width: 1680px) {
  a.Button {
    font-size: 1.4vw;
    padding: 0.2vw 0.5vw;
  }

  a.Button .arrow {
    display: inline-block;
    width: 0.6vw;
    height: 0.6vw;
    border-top: 0.18vw solid rgba(26, 39, 58, 1);
    border-right: 0.18vw solid rgba(26, 39, 58, 1);
  }
}
